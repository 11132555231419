import { LOGO_TYPES } from 'config/logos'

export const DEFAULT_USER_EMAIL = 'test@test.com'

export enum USER_ROLES {
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  USER = 'USER',
}

export enum USER_PLANS {
  FREE = 'FREE',
  PRO = 'PRO',
  PROPLUS = 'PROPLUS',
}

export enum USER_SOURCES {
  APP = 'app', // App
  APPSUMO = 'appsumo', // AppSumo
  COMPANY = 'company', // Company
  HUMANLINKER = 'humanlinker', // Humanlinker
  JULIAN = 'julian', // Julian
  PROMO = 'promo', // Promo
  VIEDEDINGUE = 'viededingue', // Vie de dingue
  WEBSITE = 'website', // Website
}

export type UserType = {
  id?: string

  paletteId?: string | null
  companyId?: string | null

  // Generic informations
  role?: USER_ROLES
  plan?: USER_PLANS
  freeSignatures?: number
  logoType?: LOGO_TYPES
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phone?: string | null
  address?: string | null
  job?: string | null
  lang?: string
  pictureUrl?: string
  logoUrl?: string
  source?: string

  // Auth fields
  isVerified?: boolean

  // Google account id
  googleId?: string

  // TODO: Tawk
  user_hash?: string

  // Generic dates
  createdAt?: Date | string
  updatedAt?: Date | string
  lastLoggedInAt?: Date | string
}
