// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { ReactNode, useCallback, useRef } from 'react'

import { useUser } from 'providers/User'
import { getTimestamp } from 'utils/date'

import { TawkContext } from './TawkContext'

export const TawkProvider = ({ children }: { children: ReactNode }) => {
  const { user, isAuth } = useUser()
  const tawkMessengerRef = useRef()

  const onLoad = useCallback(() => {
    if (isAuth) {
      // @ts-ignore
      tawkMessengerRef?.current?.setAttributes({
        // user_id: user?.id,
        name: user?.firstName
          ? `${user?.firstName} ${user?.lastName}`
          : undefined,
        email: user?.email || undefined,
        // avatar: user?.pictureUrl || undefined,
        // created_at: getTimestamp(user?.createdAt || '') || undefined,
      })
    }
  }, [user, isAuth])

  return (
    <TawkContext.Provider value={{}}>
      {isAuth ? (
        <TawkMessengerReact
          onLoad={onLoad}
          ref={tawkMessengerRef}
          propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID as string}
          widgetId={process.env.REACT_APP_TAWK_WIDGET_ID as string}
        />
      ) : undefined}
      {children}
    </TawkContext.Provider>
  )
}
