import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import NP from 'number-precision'

import { AntdProvider } from 'providers/Antd'
import { BannersProvider } from 'providers/Banners'
import { CompaniesProvider } from 'providers/Companies'
import { ConvertSignaturesProvider } from 'providers/ConvertSignatures'
import { DrawerProvider } from 'providers/Drawer'
import { DrawersProvider } from 'providers/Drawers'
import { FeathersProvider } from 'providers/Feathers'
import { FilePreviewUrlProvider } from 'providers/FilePreviewUrl'
import { FontsProvider } from 'providers/Fonts'
import { GmailIntegrationProvider } from 'providers/GmailIntegration'
import { IntlProvider } from 'providers/Intl'
import { LocaleProvider } from 'providers/Locale'
import { NotificationProvider } from 'providers/Notification'
import { PalettesProvider } from 'providers/Palettes'
import { PricingTableProvider } from 'providers/PricingTable'
import { ReactErrorProvider } from 'providers/ReactError'
import { RouterProvider } from 'providers/Router'
import { SignatureBannersProvider } from 'providers/SignatureBanners'
import { SignaturesProvider } from 'providers/Signatures'
import { TawkProvider } from 'providers/Tawk'
import { TeamUsersProvider } from 'providers/TeamUsers'
import { TeamsProvider } from 'providers/Teams'
import { TemplatesProvider } from 'providers/Templates'
import { ThemeProvider } from 'providers/Theme'
import { UserProvider } from 'providers/User'
import { UsersProvider } from 'providers/Users'
import { WorkflowsProvider } from 'providers/Workflows'
import Root from 'routes'
import { isDevEnv } from 'utils/env'

NP.enableBoundaryChecking(isDevEnv)
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {isDevEnv && <ReactQueryDevtools />}
      <FeathersProvider>
        <UserProvider>
          <LocaleProvider>
            <IntlProvider>
              <RouterProvider>
                <ThemeProvider>
                  <AntdProvider>
                    <ReactErrorProvider>
                      <PricingTableProvider>
                        <CompaniesProvider>
                          <TeamsProvider>
                            <TeamUsersProvider>
                              <UsersProvider>
                                <TemplatesProvider>
                                  <BannersProvider>
                                    <SignatureBannersProvider>
                                      <PalettesProvider>
                                        <FontsProvider>
                                          <SignaturesProvider>
                                            <GmailIntegrationProvider>
                                              <ConvertSignaturesProvider>
                                                <NotificationProvider>
                                                  <DrawersProvider>
                                                    <FilePreviewUrlProvider>
                                                      <WorkflowsProvider>
                                                        <DrawerProvider>
                                                          <TawkProvider>
                                                            <Root />
                                                          </TawkProvider>
                                                        </DrawerProvider>
                                                      </WorkflowsProvider>
                                                    </FilePreviewUrlProvider>
                                                  </DrawersProvider>
                                                </NotificationProvider>
                                              </ConvertSignaturesProvider>
                                            </GmailIntegrationProvider>
                                          </SignaturesProvider>
                                        </FontsProvider>
                                      </PalettesProvider>
                                    </SignatureBannersProvider>
                                  </BannersProvider>
                                </TemplatesProvider>
                              </UsersProvider>
                            </TeamUsersProvider>
                          </TeamsProvider>
                        </CompaniesProvider>
                      </PricingTableProvider>
                    </ReactErrorProvider>
                  </AntdProvider>
                </ThemeProvider>
              </RouterProvider>
            </IntlProvider>
          </LocaleProvider>
        </UserProvider>
      </FeathersProvider>
    </QueryClientProvider>
  )
}

export default App
